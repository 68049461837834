/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import INITIAL_STATE from "./initial-state";
import { PublishInspectorType } from "../state";
import { makeEditorState } from "../prosemirror/prosemirror";
import { addIfNotExists } from "utils/array.utils";
import { findParentNodeOfType } from "../operations/find";
import { editorStateToStory, editorStateToLiveBlogStory } from "../prosemirror/prosemirror-to-story";
import { Story, EditorConfig, AnyContribution, Contribution } from "api/story";
import { EditorState } from "prosemirror-state";
import { StoryTemplateFields } from "api/route-data/story-route-data";
import { Timestamp, StoryId, ImageId, CardId, StoryElementId } from "api/primitive-types";
import { StoryElement } from "../story-elements/story-element";
import { Image } from "api/search-media-image";

import { actions, SetIsViewingReadOnlyVersion, ReapplyEditorState } from "pages/story-editor/actions";

export type ActionType =
  | { type: actions.LOAD_STORY_DATA; payload: { story: Story } }
  | { type: actions.INIT_EDITOR_STATE; payload: { story: Story; opts: { [key: string]: any } } }
  | {
      type: actions.SET_EDITOR_STATE;
      payload: {
        editorState: EditorState;
        skipStoryUpdate?: Boolean;
        numberOfCardsShown?: number;
        cardsLoaded?: CardId[];
        currentCardLoading?: { card: CardId; elements: StoryElementId[] } | null;
        cardsToLoad?: CardId[];
      };
    }
  | { type: actions.UPDATE_TEMPLATE_FIELDS; payload: { storyTemplateFields: StoryTemplateFields | {} } }
  | { type: actions.UPDATE_EDITOR_CONFIG; payload: { editorConfig: EditorConfig | null } }
  | { type: actions.SET_STORY_VALIDATION_ERRORS; payload: { errors: {} } }
  | { type: actions.UPDATE_PUBLISH_INSPECTOR; payload: { key: PublishInspectorType } }
  | { type: actions.UPDATE_STORY_PUBLISH_AT; payload: { timestamp: Timestamp | null } }
  | { type: actions.SET_PUBLISH_INSPECTOR_VALIDATION_ERRORS; payload: { message: string } }
  | { type: actions.OPEN_STORY_RESTORE_MODAL; payload: { value: boolean; versionId: StoryId } }
  | { type: actions.CLOSE_STORY_RESTORE_MODAL }
  | { type: actions.UPDATE_UI_CONTRIBUTIONS; payload: AnyContribution[] }
  | { type: actions.LOAD_AUTHOR_CONTRIBUTIONS; payload: Contribution[] }
  | { type: actions.UPDATE_AUTHOR_CONTRIBUTIONS; payload: Contribution[] }
  | {
      type: actions.OPEN_PHOTO_EDITOR;
      payload: { image: Image; imageAs: { type: string; subtype?: string }; imageId: ImageId };
    }
  | { type: actions.CLOSE_PHOTO_EDITOR }
  | { type: actions.TOGGLE_STORY_CLEAN_UP_CONFIRMATION; payload: { action: any } }
  | { type: actions.TOGGLE_STORY_SEO_CHECKED }
  | { type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT; payload: { storyElement: StoryElement } }
  | { type: actions.RESET_STORY_EDITOR_STATE }
  | { type: actions.SET_BANNER; payload: { banner: {} | null } }
  | { type: actions.START_SAVING_STORY }
  | { type: actions.STOP_SAVING_STORY }
  | { type: actions.STORY_CHANGE_IN_PROGRESS }
  | { type: actions.PUBLISHING_STORY_INIT }
  | { type: actions.PUBLISHING_STORY_FAILURE }
  | { type: actions.PLAGIARISM_CHECK_INIT }
  | { type: actions.PLAGIARISM_CHECK_SUCCESS; payload: { message: string; url: string } }
  | { type: actions.PLAGIARISM_CHECK_FAILURE; payload: { error: Error } }
  | { type: actions.IMAGE_UPDATE_ERROR; payload: { action: any } }
  | { type: actions.MEDIA_LIBRARY_IMAGE_SAVE_INIT }
  | { type: actions.STORY_EDITOR_CLEAR_MEDIA }
  | SetIsViewingReadOnlyVersion
  | ReapplyEditorState;

const cardsFromTree = (tree) => tree.map((card) => card["content-id"]);

export const calculateTreeToDisplay = (tree, numberOfCardsToDisplay) => {
  let treeToDisplay, treeRemainingToDisplay;

  switch (numberOfCardsToDisplay) {
    case 0:
      treeToDisplay = [];
      treeRemainingToDisplay = tree;
      break;

    case tree.length:
      treeToDisplay = tree;
      treeRemainingToDisplay = [];
      break;

    default:
      treeToDisplay = tree.slice(0, numberOfCardsToDisplay);
      treeRemainingToDisplay = tree.slice(numberOfCardsToDisplay, tree.length);
  }

  const cardsToDisplay = cardsFromTree(treeToDisplay);
  const cardsRemainingToDisplay = cardsFromTree(treeRemainingToDisplay);

  return { treeToDisplay, cardsToDisplay, cardsRemainingToDisplay };
};

const isSponsoredContentEnabled = (metadata: any) => (metadata ? metadata.hasOwnProperty("sponsored-by") : false);

export default function storyEditorReducer(state = INITIAL_STATE, action: ActionType) {
  switch (action.type) {
    case actions.LOAD_STORY_DATA: {
      return {
        ...state,
        story: action.payload.story,
        ui: {
          ...state.ui,
          showSponsoredContent: isSponsoredContentEnabled(action.payload.story && action.payload.story.metadata)
        }
      };
    }

    case actions.INIT_EDITOR_STATE: {
      const { story, opts: { numberOfCardsToLoad = 0, loadAllElements = false } = {} } = action.payload;
      const { tree } = story;
      const isLiveBlog = story["story-template"] === "live-blog";

      const numberOfCardsToDisplay = isLiveBlog
        ? numberOfCardsToLoad
        : loadAllElements
        ? tree.length
        : numberOfCardsToLoad;

      const { treeToDisplay, cardsToDisplay, cardsRemainingToDisplay } = calculateTreeToDisplay(
        tree,
        numberOfCardsToDisplay
      );

      const editorState = makeEditorState({ ...story, tree: treeToDisplay }, {});
      const uiState = {
        ...state.ui,
        ...(isLiveBlog && { numberOfCardsShown: numberOfCardsToDisplay }),
        cardsLoaded: cardsToDisplay,
        cardsToLoad: cardsRemainingToDisplay,
        currentCardLoading: null
      };

      return {
        ...state,
        editorState,
        initialEditorState: editorState,
        ui: uiState
      };
    }

    case actions.SET_EDITOR_STATE: {
      if (!action.payload.skipStoryUpdate) {
        const { editorState, cardsLoaded } = action.payload;
        let updatedState = { ...state, editorState, initialEditorState: editorState };

        if (!(state.editorState && editorState.doc.eq(state.editorState.doc))) {
          const cardNP = findParentNodeOfType(editorState, "card");
          if (cardNP) {
            const cardNodeID = cardNP.node.attrs.id;
            const updatedStory =
              state.story["story-template"] === "live-blog"
                ? editorStateToLiveBlogStory(editorState, state.story, state.ui.cardsToLoad)
                : editorStateToStory(editorState, state.story, state.ui.cardsToLoad, state.ui.currentCardLoading);
            return {
              ...updatedState,
              story: {
                ...updatedStory,
                "updated-cards": addIfNotExists(state.story["updated-cards"], cardNodeID)
              },
              ui: {
                ...state.ui,
                isStoryModified: true,
                isStoryChangeInProgress: false,
                lastUpdatedTime: Date.now()
              }
            };
          }
        }
        return {
          ...updatedState,
          ui: {
            ...updatedState.ui,
            cardsLoaded: cardsLoaded || updatedState.ui.cardsLoaded,
            isStoryChangeInProgress: false
          }
        };
      } else {
        let { editorState, numberOfCardsShown, cardsLoaded, currentCardLoading, cardsToLoad } = action.payload;
        const remainingCards = state.story.tree
          .slice(numberOfCardsShown, state.story.tree.length)
          .map((card) => card["content-id"]);
        if (cardsLoaded) {
          return {
            ...state,
            editorState,
            initialEditorState: editorState,
            ui: {
              ...state.ui,
              numberOfCardsShown: numberOfCardsShown || 0,
              cardsLoaded,
              currentCardLoading,
              cardsToLoad: cardsToLoad || remainingCards
            }
          };
        }
        return {
          ...state,
          editorState,
          initialEditorState: editorState,
          ui: {
            ...state.ui,
            numberOfCardsShown,
            cardsToLoad: remainingCards
          }
        };
      }
    }

    case actions.UPDATE_TEMPLATE_FIELDS: {
      return {
        ...state,
        storyTemplateFields: action.payload.storyTemplateFields
      };
    }

    case actions.UPDATE_EDITOR_CONFIG: {
      return {
        ...state,
        editorConfig: action.payload.editorConfig
      };
    }

    case actions.SET_STORY_VALIDATION_ERRORS: {
      const errors = action.payload.errors;
      return {
        ...state,
        ui: { ...state.ui, errors }
      };
    }

    case actions.UPDATE_PUBLISH_INSPECTOR: {
      const { key } = action.payload;
      return {
        ...state,
        ui: { ...state.ui, publishInspector: key, publishInspectorValidationErrors: null }
      };
    }

    case actions.UPDATE_STORY_PUBLISH_AT: {
      const { timestamp } = action.payload;
      const changes = {
        "publish-at": timestamp
      };

      return {
        ...state,
        story: { ...state.story, ...changes }
      };
    }

    case actions.SET_PUBLISH_INSPECTOR_VALIDATION_ERRORS: {
      const publishInspectorValidationErrors = action.payload.message;
      return {
        ...state,
        ui: { ...state.ui, publishInspectorValidationErrors }
      };
    }

    case actions.OPEN_STORY_RESTORE_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showStoryRestoreModal: action.payload.value,
          restoreVersionId: action.payload.versionId
        }
      };
    }

    case actions.CLOSE_STORY_RESTORE_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showStoryRestoreModal: false,
          restoreVersionId: null
        }
      };
    }
    case actions.OPEN_PHOTO_EDITOR: {
      return {
        ...state,
        app: {
          ...state.app,
          photoEditor: {
            visible: true,
            selectedImage: action.payload.image,
            imageAs: action.payload.imageAs,
            imageId: action.payload.imageId
          }
        }
      };
    }
    case actions.CLOSE_PHOTO_EDITOR: {
      return {
        ...state,
        app: {
          ...state.app,
          photoEditor: {
            visible: false,
            selectedImage: "",
            imageAs: "",
            imageId: ""
          }
        }
      };
    }

    case actions.UPDATE_UI_CONTRIBUTIONS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          storyContributions: action.payload
        }
      };
    }

    case actions.LOAD_AUTHOR_CONTRIBUTIONS: {
      return {
        ...state,
        app: {
          ...state.app,
          storyAuthorContributions: action.payload
        }
      };
    }

    case actions.UPDATE_AUTHOR_CONTRIBUTIONS: {
      return {
        ...state,
        app: {
          ...state.app,
          storyAuthorContributions: action.payload
        },
        ui: {
          ...state.ui,
          isStoryModified: true,
          lastUpdatedTime: Date.now()
        }
      };
    }

    case actions.TOGGLE_STORY_CLEAN_UP_CONFIRMATION: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showStoryCleanUpModal: !state.ui.showStoryCleanUpModal,
          changeStatusAction: action.payload
        }
      };
    }

    case actions.TOGGLE_STORY_SEO_CHECKED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          seoChecked: !state.ui.seoChecked
        }
      };
    }

    case actions.RESET_STORY_EDITOR_STATE: {
      return INITIAL_STATE;
    }
    case actions.SET_BANNER: {
      return {
        ...state,
        ui: {
          ...state.ui,
          banner: action.payload.banner
        }
      };
    }

    case actions.START_SAVING_STORY: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isStorySaving: true
        }
      };
    }
    case actions.STOP_SAVING_STORY: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isStorySaving: false
        }
      };
    }

    case actions.STORY_CHANGE_IN_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isStoryChangeInProgress: true
        }
      };
    }

    case actions.PUBLISHING_STORY_INIT: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isStoryPublishing: true
        }
      };
    }

    case actions.PUBLISHING_STORY_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isStoryPublishing: false,
          isStatusTransitionInProgress: false
        }
      };
    }

    case actions.PLAGIARISM_CHECK_INIT: {
      return {
        ...state,
        plagiarismCheck: {
          ...state.plagiarismCheck,
          loading: true
        }
      };
    }

    case actions.PLAGIARISM_CHECK_SUCCESS: {
      const { message, url } = action.payload;
      return {
        ...state,
        plagiarismCheck: {
          ...state.plagiarismCheck,
          message,
          url,
          loading: false
        }
      };
    }

    case actions.PLAGIARISM_CHECK_FAILURE: {
      return {
        ...state,
        plagiarismCheck: {
          ...state.plagiarismCheck,
          message: null,
          url: null,
          loading: false
        }
      };
    }

    case actions.SET_IS_VIEWING_READONLY_VERSION: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isViewingReadOnlyVersion: action.payload.isViewingReadOnlyVersion
        }
      };
    }

    case actions.REAPPLY_EDITOR_STATE: {
      const editorState = state.editorState as EditorState;
      const updatedEditorState = editorState.apply(editorState.tr);
      return {
        ...state,
        editorState: updatedEditorState,
        initialEditorState: updatedEditorState
      };
    }

    case actions.IMAGE_UPDATE_ERROR: {
      return {
        ...state,
        errors: action.payload,
        ui: {
          ...state.ui,
          errors: {
            ...state.ui.errors,
            inspector: action.payload
          },
          isSaving: false
        }
      };
    }

    case actions.MEDIA_LIBRARY_IMAGE_SAVE_INIT: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isSaving: true
        }
      };
    }

    case actions.STORY_EDITOR_CLEAR_MEDIA: {
      return {
        ...state,
        errors: []
      };
    }

    default:
      return state;
  }
}
