/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { useState, useEffect, FunctionComponent, useCallback } from "react";
import { debounce } from "lodash";

export function useWindowScroll() {
  const [prevScrollPosition, setScrollPosition] = useState(0);
  const [scrollDifference, setScrollDifference] = useState(0);

  const handleScroll = useCallback(
    debounce(() => {
      const currentScrollPosition = window.pageYOffset;
      setScrollDifference(currentScrollPosition - prevScrollPosition);
      setScrollPosition(currentScrollPosition);
    }, 100),
    [prevScrollPosition]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return scrollDifference;
}

export function useVisibleOnScroll(buffer = 0) {
  const [buttonVisible, setButtonVisible] = useState(true);
  const scrollDifference = useWindowScroll();

  if (buttonVisible && scrollDifference > buffer) {
    setButtonVisible(false);
  }
  if (!buttonVisible && scrollDifference < -buffer) {
    setButtonVisible(true);
  }
  return buttonVisible;
}

/* Since Story Editor is a class component, using hooks inside it will not work.
Hence the below function `VisibleOnScroll` was created
Reference: https://infinum.com/the-capsized-eight/how-to-use-react-hooks-in-class-components */

type ChildrenProps = (x: boolean) => any;
interface OnScrollProps {
  children: ChildrenProps;
}

export const VisibleOnScroll: FunctionComponent<OnScrollProps> = ({ children }) => {
  const onScroll = useVisibleOnScroll(5);

  return children(onScroll);
};
