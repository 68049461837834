/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { Fragment } from "react";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectIsDesktopSizeViewport } from "store/viewport";
import Avatar from "components/avatar/avatar";
import AddNewMenu from "components/add-new-menu/add-new-menu";
import { Member, Publisher, Features } from "api/route-data/route-data";
import styles from "./header.module.css";
import WithGAEvent from "helpers/ga-analytics/with-ga-event";
import { AddNewButtonMobile, AddNewHeaderButtonDesktop } from "components/add-new-menu/add-new-header-button";

const cx = classnames.bind(styles);

interface HeaderOwnProps {
  showAvatar?: boolean;
  hideAddNewButton?: boolean;
}

interface HeaderStateProps {
  helpUrl: string;
  member: Member;
  publisher: Publisher;
  isBannerPresent: boolean;
  showAddNewButton?: boolean;
  canCreateBreakingNews?: boolean;
  canCreateCollections?: boolean;
  canCreateStory?: boolean;
  canAddUser?: boolean;
  canCreatePushNotifications?: boolean;
  canCreateForms?: boolean;
  isTaskPlannerEnabled?: boolean;
  isDesktopSizeViewport: boolean;
  storyTemplates?: any;
}

type HeaderProps = HeaderOwnProps & HeaderStateProps;

interface PartialAppState {
  config: {
    storyTemplates?: any;
    "help-url": string;
    publisher: Publisher;
    member: Member;
    publisherWideBannerMessage: string | null;
  };
  features: Features;
  storyEditor: {
    story?: any;
    ui: {
      banner: { message: string } | null;
    };
  };
  usersAndRoles: {
    usersPage: {
      ui: {
        banner: {
          message: string;
        };
      };
    };
  };
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

const Header: React.FC<HeaderProps> = ({
  helpUrl,
  member,
  publisher,
  children,
  isBannerPresent,
  showAvatar = true,
  showAddNewButton,
  canCreateBreakingNews,
  canCreateCollections,
  canCreateStory,
  canAddUser,
  canCreatePushNotifications,
  canCreateForms,
  isTaskPlannerEnabled,
  isDesktopSizeViewport,
  storyTemplates
}) => {
  const mastheadClass = cx("masthead", { "with-banner": isBannerPresent });
  return (
    <Fragment>
      <header className={mastheadClass}>
        {isDesktopSizeViewport && (
          <div>
            {showAddNewButton && (
              <AddNewMenu
                canCreateBreakingNews={canCreateBreakingNews}
                canCreateCollections={canCreateCollections}
                canCreateStory={canCreateStory}
                canAddUser={canAddUser}
                canCreatePushNotifications={canCreatePushNotifications}
                canCreateForms={canCreateForms}
                storyTemplates={storyTemplates}
                Component={AddNewHeaderButtonDesktop}
              />
            )}
          </div>
        )}

        <div className={styles["children-container"]} data-test-id="header-children-container">
          {children}
        </div>
        <WithGAEvent label="header-me">
          {showAvatar && (
            <Avatar
              member={member}
              publisher={publisher}
              isTaskPlannerEnabled={isTaskPlannerEnabled}
              helpUrl={helpUrl}
            />
          )}
        </WithGAEvent>
      </header>
      {!isDesktopSizeViewport && (
        <div>
          {showAddNewButton && (
            <AddNewMenu
              canCreateBreakingNews={canCreateBreakingNews}
              canCreateCollections={canCreateCollections}
              canCreateStory={canCreateStory}
              canAddUser={canAddUser}
              canCreatePushNotifications={canCreatePushNotifications}
              storyTemplates={storyTemplates}
              Component={AddNewButtonMobile}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: PartialAppState, ownProps: HeaderOwnProps): HeaderProps => {
  return {
    helpUrl: state.config["help-url"],
    publisher: state.config.publisher,
    member: state.config.member,
    showAddNewButton: state.features.showAddNewButton && !ownProps.hideAddNewButton,
    canCreateBreakingNews: state.features.canCreateBreakingNews,
    canCreateCollections: state.features.canCreateCollections,
    canCreateStory: state.features.canCreateStory,
    canAddUser: state.features.canAddAuthor,
    canCreatePushNotifications: state.features.canCreatePushNotifications,
    canCreateForms: state.features.canCreateForms,
    isBannerPresent: !!(
      state.config.publisherWideBannerMessage ||
      (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message) ||
      state.usersAndRoles.usersPage.ui.banner.message
    ),
    isTaskPlannerEnabled: !!state.features.isTaskPlannerEnabled,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    storyTemplates: state.config.storyTemplates
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);

export { Header };
