/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  getStaticPages,
  createNewStaticPage,
  updateStaticPage,
  StaticPage,
  StaticPageStatus,
  deleteStaticPage,
  getStaticPage
} from "api/static-pages";
import {
  initLoadStaticPages,
  loadStaticPagesSuccess,
  loadStaticPagesFailure,
  initLoadNextStaticPages,
  loadNextStaticPagesSuccess,
  loadNextStaticPagesFailure,
  setCurrentStaticPage,
  initSaveStaticPage,
  saveStaticPageSuccess,
  saveStaticPageFailure,
  initPublishStaticPage,
  publishStaticPageFailure,
  publishStaticPageSuccess,
  initRetractStaticPage,
  retractStaticPageSuccess,
  retractStaticPageFailure,
  initDeleteStaticPage,
  deleteStaticPageFailure,
  deleteStaticPageSuccess,
  setCurrentStaticPageError,
  closeDeleteConfirmation
} from "./action-creators";
import { PartialAppState, emptyStaticPage } from "./state";
import { navigate } from "utils/routes.utils";
import { STATIC_PAGES_INDEX_PATH, STATIC_PAGES_EDIT_PATH } from "./routes";
import { StaticPageNotFoundError, StaticPageValidationError } from "./errors";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";

export const loadStaticPagesAction = (offset: number = 0) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    dispatch(initLoadStaticPages());
    const staticPagesResponse = await getStaticPages(offset);
    dispatch(loadStaticPagesSuccess(staticPagesResponse["static-pages"], staticPagesResponse["pagination"]));
  } catch (error) {
    dispatch(loadStaticPagesFailure(error));
  }
};

export const createNewStaticPageAction = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(setCurrentStaticPage(emptyStaticPage));
};

export const loadCurrentStaticPageAction = (id: number) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const currentStaticPage =
    getState().staticPages.staticPages.find((page) => page.id === id) || (await getStaticPage(id));
  if (currentStaticPage) {
    dispatch(setCurrentStaticPage(currentStaticPage));
  } else {
    dispatch(setCurrentStaticPageError(new StaticPageNotFoundError(id, "")));
  }
};

export const saveCurrentStaticPageAction = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const currentStaticPage = getState().staticPages.app.currentStaticPage;
  if (currentStaticPage) {
    const currentStaticPageWithSourcePath = { ...currentStaticPage, "source-path": `/${currentStaticPage.slug}` };

    if (currentStaticPage.hasOwnProperty("id")) {
      dispatch(initSaveStaticPage());

      updateStaticPage(currentStaticPage as StaticPage)
        .then(() => {
          dispatch(saveStaticPageSuccess());
          dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: {
              message: t("staticPages.save-success")
            }
          });
        })
        .catch((error) => {
          dispatch(saveStaticPageFailure(new StaticPageValidationError("Save Failed", JSON.parse(error.message))));
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: {
              message: t("staticPages.save-failed")
            }
          });
        });
    } else {
      dispatch(initSaveStaticPage());

      createNewStaticPage(currentStaticPageWithSourcePath)
        .then((response) => {
          dispatch(saveStaticPageSuccess());
          dispatch(setCurrentStaticPage(response.id));
          dispatch(navigate(STATIC_PAGES_EDIT_PATH, { id: response.id.id }));
          dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: {
              message: t("staticPages.create-success")
            }
          });
        })
        .catch((error) => {
          dispatch(saveStaticPageFailure(new StaticPageValidationError("Save Failed", JSON.parse(error.message))));
          dispatch({
            type: NOTIFICATION_ERROR,
            payload: {
              message: t("staticPages.save-failed")
            }
          });
        });
    }
  }
};

export const publishCurrentStaticPageAction = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const currentStaticPage = getState().staticPages.app.currentStaticPage;
  if (currentStaticPage) {
    dispatch(initPublishStaticPage());
    const currentStaticPageWithStatus = { ...currentStaticPage, status: StaticPageStatus.Publish } as StaticPage;
    updateStaticPage(currentStaticPageWithStatus)
      .then(() => {
        setTimeout(() => {
          dispatch(publishStaticPageSuccess());
          dispatch(navigate(STATIC_PAGES_INDEX_PATH));
          dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: {
              message: t("staticPages.publish-success")
            }
          });
        }, 3000);
      })
      .catch((error) => {
        dispatch(publishStaticPageFailure(new StaticPageValidationError("Save Failed", JSON.parse(error.message))));
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            message: t("staticPages.publish-failed")
          }
        });
      });
  }
};

export const retractCurrentStaticPageAction = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const currentStaticPage = getState().staticPages.app.currentStaticPage;
  if (currentStaticPage) {
    dispatch(initRetractStaticPage());
    const currentStaticPageWithStatus = { ...currentStaticPage, status: StaticPageStatus.Retract } as StaticPage;
    updateStaticPage(currentStaticPageWithStatus)
      .then(() => {
        setTimeout(() => {
          dispatch(retractStaticPageSuccess());
          dispatch(navigate(STATIC_PAGES_INDEX_PATH));
          dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: {
              message: t("staticPages.retract-success")
            }
          });
        }, 3000);
      })
      .catch((error) => {
        dispatch(retractStaticPageFailure(new StaticPageValidationError("Save Failed", JSON.parse(error.message))));
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            message: t("staticPages.retract-failed")
          }
        });
      });
  }
};

export const deleteCurrentStaticPageAction = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const currentStaticPage = getState().staticPages.app.currentStaticPage as StaticPage;
  if (currentStaticPage) {
    dispatch(initDeleteStaticPage());
    deleteStaticPage(currentStaticPage.id)
      .then(() => {
        setTimeout(() => {
          dispatch(deleteStaticPageSuccess());
          dispatch(closeDeleteConfirmation());
          dispatch(navigate(STATIC_PAGES_INDEX_PATH));
          dispatch({
            type: NOTIFICATION_SUCCESS,
            payload: {
              message: t("staticPages.delete-success")
            }
          });
        }, 3000);
      })
      .catch((error) => {
        dispatch(deleteStaticPageFailure(JSON.parse(error)));
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            message: t("staticPages.delete-failed")
          }
        });
      });
  }
};

export const loadNextStaticPagesAction = (offset: number = 0) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    dispatch(initLoadNextStaticPages());
    const staticPagesResponse = await getStaticPages(offset);
    dispatch(loadNextStaticPagesSuccess(staticPagesResponse["static-pages"], staticPagesResponse["pagination"]));
  } catch (error) {
    dispatch(loadNextStaticPagesFailure(error));
  }
};
