/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import * as actions from "../../actions";
import ActionBar from "components/action-bar/action-bar";
import Menu from "components/menu/menu";
import { compose } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";
import { fetchWorkspaceData } from "../../action-creators";
import { PartialAppState, FilterSet, Source } from "api/workspace";
import { ThunkDispatch } from "redux-thunk";
import { match } from "react-router";

interface WorkspaceActionBarProps {
  sources: Source[];
  currentFilterSet: FilterSet;
  setStoriesOnFilterChange: (key: string, value: any) => void;
  isBannerPresent: boolean;
  isDesktopSizeViewport: boolean;
  resetStories: () => void;
  match: match<{ tabSlug: string }>;
}

const generateSourceItemsForSidebar = (sources: Source[], selectedSource: Source) =>
  sources.length > 0
    ? sources.map(({ source }) => {
        return {
          source: source,
          isSelected: selectedSource.source === source
        };
      })
    : [];

const WorkspaceActionBar = ({
  sources,
  currentFilterSet,
  setStoriesOnFilterChange,
  isBannerPresent,
  isDesktopSizeViewport,
  resetStories
}: WorkspaceActionBarProps) => {
  return (
    <React.Fragment>
      {sources.length ? (
        <ActionBar
          isBannerPresent={isBannerPresent}
          isDesktopSizeViewport={isDesktopSizeViewport}
          renderContents={(onItemClickCallback) => (
            <Menu<{ source: string | undefined; isSelected: boolean }>
              title={t("workspace.sidebar.source_plural")}
              items={generateSourceItemsForSidebar(sources, currentFilterSet.source)}
              onItemClick={(item) => {
                resetStories();
                setStoriesOnFilterChange("source", { source: item.source, categories: [] });
                onItemClickCallback();
              }}
              getItemLabel={(item) => t(`workspace.filter.sources.${item.source}`, item.source) || ""}
              isItemSelected={(item) => item.isSelected}
            />
          )}
        />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState, ownProps: WorkspaceActionBarProps) => {
  return {
    sources: state.config.sources,
    currentFilterSet: state.workspace.currentFilterSet
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: WorkspaceActionBarProps) => {
  return {
    resetStories: () => dispatch({ type: actions.WORKSPACE_RESET_STORIES }),
    setStoriesOnFilterChange: (key: string, value: any) => {
      dispatch({ type: actions.WORKSPACE_UPDATE_FILTER, payload: { key, value } });
      dispatch({ type: actions.WORKSPACE_RESET_STORIES });
      dispatch(fetchWorkspaceData(ownProps.match.params.tabSlug, null));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(WorkspaceActionBar);

export { WorkspaceActionBar };
