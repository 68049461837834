/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import nextId from "react-id-generator";
import { t } from "i18n";
import { Collection } from "api/collection-items";
import {
  collectionFetchLimit,
  fetchCollections,
  PartialAppState,
  updateFilters
} from "store/collection/collections-dashboard";
import InfiniteScroll from "react-infinite-scroll-component";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import Spinner from "components/spinner/spinner";
import CollectionCard from "pages/collections/dashboard/components/collection-card/collection-card";
import styles from "./collection-list.module.css";

interface DispatchProps {
  loadNextCollections: (collectionsCount: number) => void;
}

interface StateProps {
  showLoader: boolean;
  collections: Collection[];
  filtersMatchCount: number;
}

export const CollectionList: React.FC<DispatchProps & StateProps> = ({
  showLoader,
  collections,
  filtersMatchCount,
  loadNextCollections
}) => {
  const collectionsCount = collections.length;
  const hasMore = filtersMatchCount - collectionsCount > 0;
  const endMessage =
    collectionsCount === 0
      ? t("collections.dashboard.messages.no-collections")
      : t("collections.dashboard.messages.no-more-collections");
  const loadNext = () => loadNextCollections(collectionsCount);

  return showLoader ? (
    <Spinner
      classname={styles["spinner"]}
      message={t("collections.dashboard.messages.loading")}
      data-test-id={"collections-list-spinner"}
    />
  ) : (
    <InfiniteScroll
      dataLength={collectionsCount}
      next={loadNext}
      hasMore={hasMore}
      loader={
        <Spinner classname={styles["infinite-scroll-spinner"]} message={t("collections.dashboard.messages.loading")} />
      }
      endMessage={
        <NoContentCard>
          <File />
          {endMessage}
        </NoContentCard>
      }>
      <div className={styles["collection-list"]}>
        {collections.map((collection) => (
          <CollectionCard collection={collection} key={nextId("collection-card-")} />
        ))}
      </div>
    </InfiniteScroll>
  );
};

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    showLoader: state.collectionsDashboard.ui.showLoader,
    collections: state.collectionsDashboard.collections,
    filtersMatchCount: state.collectionsDashboard.filtersMatchCount
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    loadNextCollections: async (collectionsCount) => {
      dispatch(updateFilters({ limit: collectionsCount + collectionFetchLimit }));
      await dispatch(fetchCollections({}));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionList);
