/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import React, { useRef, useEffect, useState } from "react";
import { t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import OpenInNewTab from "components/icons/open";
import styles from "./preview.module.css";
import { COLLECTIONS_SELECTED_COLLECTION_PATH } from "pages/collections/routes";
import { AnyCollection } from "api/story-collection";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import { navigate } from "utils/routes.utils";
import { debounce } from "lodash";
import { loadCollectionPreview } from "pages/collections/async-action-creators";

interface CollectionPreviewStateProps {
  previewBase: string;
  previewId: string;
  currentCollection: AnyCollection | null;
  config: {
    publisher: { "website-url": string };
    "preview-id": string;
  };
}

export const CollectionPreview = ({ isActive }) => {
  const dispatch = useDispatch();
  const configState = useSelector((state: CollectionPreviewStateProps) => state) || {};
  const selectedCollection = get(configState, ["collections", "collectionPage", "selectedCollection"], {}) || {};

  const { publisher: { "website-url": previewBase = "" } = {}, "preview-id": previewId = "" } =
    configState.config || {};

  const [previewOptions, setOptions] = useState([{ label: "Home", value: "home" }]);
  const [currentPreview, setPreview] = useState({ label: "", value: "" });
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [previewErrorMsg, setPreviewErrorMsg] = useState(false);
  const previewUrl = `${previewBase}/preview/${previewId}/collection/${currentPreview.value}`;

  useEffect(() => {
    if (selectedCollection) {
      const name = selectedCollection.name || "New";
      const slug = selectedCollection.slug || "new";
      const newOptions = [
        { label: "Home", value: "home" },
        { label: name, value: slug }
      ];
      setOptions(newOptions);
      setPreview({ label: name, value: slug });
    }
  }, [selectedCollection]);

  const debouncedLoadCollectionPreview = useRef(
    debounce(async () => {
      await dispatch(loadCollectionPreview());
    }, 5000)
  ).current;

  useEffect(() => {
    selectedCollection && debouncedLoadCollectionPreview();
    return () => {
      selectedCollection && debouncedLoadCollectionPreview.cancel();
    };
  }, [debouncedLoadCollectionPreview, selectedCollection]);

  useEffect(() => {
    if (isActive) {
      (async () => {
        try {
          const response = await fetch(previewUrl, { method: "GET" });
          if (response.status === 404) {
            setPreviewErrorMsg(true);
          } else {
            setPreviewErrorMsg(false);
          }
        } catch {
          setPreviewErrorMsg(true);
        }
      })();
    }
  }, [previewUrl, isActive]);

  const closeSettingsInspector = (collectionId) => {
    dispatch(navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" }));
  };

  return (
    <Inspector
      title={t("collections.preview")}
      actionButtonLabel={t("collections.open-preview")}
      iconComponent={() => <OpenInNewTab fill="#fff" />}
      onActionButtonClick={() => window.open(previewUrl)}
      isActive={isActive}
      onClose={() => closeSettingsInspector(selectedCollection.id)}
      level={0}>
      <Select
        name={t("collections.select-preview-page")}
        value={currentPreview}
        options={previewOptions}
        onChange={(selectedOption: any) => {
          setPreview(selectedOption);
        }}
        label={t("collections.select-preview-page")}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isMulti={false}
      />
      <div data-test-id="collection-preview-container" className={styles["preview-container"]}>
        {previewErrorMsg ? (
          <NoContentCard>
            <File />
            <div data-test-id="no-preview-message">{t("collections.no-preview-message")}</div>
          </NoContentCard>
        ) : (
          <iframe data-test-id="preview-iframe" title="Preview" src={previewUrl} ref={iframeRef} />
        )}
      </div>
    </Inspector>
  );
};
