/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Image } from "./search-media-image";
import {
  Timestamp,
  CardId,
  StoryElementId,
  ClientId,
  StoryId,
  UUID,
  CompositeElementID,
  ContributorId,
  TaskId
} from "./primitive-types";
import { client } from "api/client";
import { Author } from "api/author";
import { SocialAccount, SocialCard } from "./social";
import { BNSocialCard } from "./breaking-news";
import { Tag } from "./tag";
import { Entity } from "./entity";
import { Poll } from "api/polls";
import { Target } from "./push-notification";

export enum StoryElementPolltypeSubtype {
  OpinionPoll = "opinion-poll"
}

export enum StoryElementFileSubtype {
  Attachment = "attachment"
}

export enum StoryElementDataSubtype {
  Table = "table"
}

export enum StoryElementCompositeSubtype {
  ImageGallery = "image-gallery",
  Ingredients = "ingredients",
  Playlist = "playlist",
  References = "references"
}

export enum StoryElementExternalFileSubtype {
  Bitgravity = "bitgravity-video",
  Brightcove = "brightcove-video",
  JWPlayer = "jwplayer",
  Vod = "vod-video"
}

export enum StoryElementEmbedSubtype {
  AVMMVidible = "avmm-vidible-video",
  Dailymotion = "dailymotion-video",
  DailymotionEmbedScript = "dailymotion-embed-script",
  Dilmot = "dilmot-q-and-a",
  FacebookPost = "facebook-post",
  FacebookVideo = "facebook-video",
  Instagram = "instagram",
  SocialMedia = "social-media",
  Tweet = "tweet",
  VidibleVideo = "vidible-video"
}

export enum StoryElementVideoSubtype {
  VideoClip = "video-clip"
}

export enum StoryElementTextSubtype {
  AlsoRead = "also-read",
  Answer = "answer",
  BigFact = "bigfact",
  BlockQuote = "blockquote",
  Blurb = "blurb",
  EmbedStory = "embed-story",
  QandA = "q-and-a",
  Question = "question",
  Quote = "quote",
  Cta = "cta",
  Summary = "summary",
  URL = "url"
}

export enum StoryElementType {
  Composite = "composite",
  Data = "data",
  ExternalFile = "external-file",
  File = "file",
  Image = "image",
  ImageGallery = "image-gallery",
  Instagram = "instagram",
  JSEmbed = "jsembed",
  Polltype = "polltype",
  Soundcloud = "soundcloud-audio",
  Text = "text",
  Title = "title",
  Tweet = "tweet",
  Video = "video",
  Youtube = "youtube-video"
}

// prettier-ignore
export type StoryElementSubType<T> =
  T extends StoryElementType.Text ? StoryElementTextSubtype :
  T extends StoryElementType.JSEmbed ? StoryElementEmbedSubtype :
  T extends StoryElementType.ExternalFile ? StoryElementExternalFileSubtype :
  T extends StoryElementType.Composite ? StoryElementCompositeSubtype :
  T extends StoryElementType.Data ? StoryElementDataSubtype :
  T extends StoryElementType.File ? StoryElementFileSubtype :
  T extends StoryElementType.Polltype ? StoryElementPolltypeSubtype :
  T extends StoryElementType.Video ? StoryElementVideoSubtype :
  null;

export interface StoryElement {
  description?: string;
  type: StoryElementType;
  "family-id"?: StoryElementId;
  "card-id": CardId;
  id: StoryElementId;
  "card-version-id"?: CardId;
  metadata: any;
  subtype: StoryElementSubType<StoryElementType>;
  "client-id"?: ClientId;
  text?: string;
  image?: Image;
  title?: string;
  url?: string;
  "embed-url"?: string;
  "polltype-id"?: number;
  "file-name"?: string;
  "file-type"?: string;
  "s3-key"?: string;
  "content-type"?: string;
}

export interface CompositeStoryElement extends StoryElement {
  tree: Array<StoryElementId>;
}

export interface ChildStoryElement {
  description?: string;
  type: StoryElementType;
  "family-id"?: StoryElementId;
  id: StoryElementId;
  metadata: any;
  subtype: StoryElementSubType<StoryElementType>;
  "client-id"?: ClientId;
  text?: string;
  image?: Image;
  title?: string;
  "composite-element-id": CompositeElementID;
}

export interface Card {
  tree: string[];
  "card-updated-at"?: Timestamp;
  "content-version-id"?: string;
  "card-added-at"?: Timestamp;
  status?: string;
  id: CardId;
  "content-id": CardId;
  version?: number;
  metadata?: {
    "social-share"?: {
      shareable?: Boolean;
      message?: String;
      image?: Image | null;
      "external-id"?: string;
    };
    attributes?: {
      [key: string]: Array<String> | Boolean | any;
    };
  };
  "client-id"?: ClientId;
  "content-type"?: string;
  "version-id"?: UUID;
}
export interface StoryElements {
  [key: string]: StoryElement | CompositeStoryElement | ChildStoryElement;
}

export interface StoryElementUI {
  poll?: Poll;
}

export interface StoryElementsUI {
  [key: string]: StoryElementUI;
}

export enum StoryStatus {
  Draft = "draft",
  PendingApproval = "pending-approval",
  Approved = "approved",
  Scheduled = "scheduled",
  Published = "published",
  Rejected = "rejected"
}

export enum DraftActions {
  StorySubmit = "story-submit"
}

export enum PendingApprovalActions {
  StoryReject = "story-reject",
  StoryApprove = "story-approve"
}

export enum ApprovedActions {
  StoryReject = "story-reject",
  StoryPublish = "story-publish",
  StorySchedule = "story-schedule",
  StoryCorrectionPublish = "story-correction-publish"
}

export enum ScheduledActions {
  StoryScheduleModify = "story-schedule-modify",
  StoryScheduleCancel = "story-schedule-cancel"
}
enum PublishedActions {
  StoryRetract = "story-retract"
}
enum RejectedActions {
  StorySave = "story-save"
}

enum VersionActions {
  VersionCreated = "version-created"
}

export enum WorkflowActions {
  StorySubmit = "story-submit",
  StoryReject = "story-reject",
  StoryApprove = "story-approve",
  StoryPublish = "story-publish",
  StorySchedule = "story-schedule",
  StoryScheduleWithCollection = "story-schedule-with-collection",
  StoryScheduleWhenEmbargoEnds = "story-schedule-when-embargo-ends",
  StoryCorrectionPublish = "story-correction-publish",
  StoryScheduleModify = "story-schedule-modify",
  StoryScheduleCancel = "story-schedule-cancel",
  StoryRetract = "story-retract",
  StorySave = "story-save",
  VersionCreated = "version-created",
  StoryClose = "story-close"
}

export interface StoryTag extends Tag {
  "tag-type"?: string;
}

export interface StoryEntity extends Entity {
  "tag-type"?: string;
}

interface LastUpdatedBy {
  "member-id": number;
  "member-name": string;
}

export interface Story {
  "updated-at": number;
  seo: SEO;
  "assignee-id": number;
  "author-name": string;
  tags: any[];
  headline: string;
  "storyline-id": null;
  votes: object;
  "push-notification-targets": Array<Target>;
  "story-content-id": string;
  "is-published": boolean;
  "is-embargoed": boolean;
  slug: string;
  "last-published-at": Timestamp | null;
  tree: Tree[];
  subheadline: string;
  alternative: Alternative | {};
  sections: any[];
  "updated-cards": any[];
  "access-level-value": null;
  "content-created-at": number;
  "owner-name": string;
  "custom-slug": null;
  "push-notification": string | null;
  "publisher-id": number;
  comments: null;
  "hero-image": Image;
  entities: object | any;
  "published-at": null;
  "story-elements": StoryElements;
  "breaking-news-linked-story-id": UUID | null;
  "storyline-title": null;
  "private-fields": EditorNotes | null;
  summary: string | null;
  "push-notification-title": null;
  "external-id": null;
  "canonical-url": null;
  autotags: any[];
  "linked-entities": any[];
  status: StoryStatus;
  "bullet-type": string;
  id: StoryId;
  "editor-features": Object;
  cards: { [key: string]: Card };
  "story-version-id": StoryId;
  "content-type": string;
  "content-updated-at": number;
  "author-id": number;
  "owner-id": number;
  access: AccessType;
  "first-published-at": Timestamp | null;
  version: number;
  "story-template": StoryTemplate;
  "sequence-no": null;
  "created-at": number;
  authors: Author[] | null;
  contributors: AnyContribution[];
  "story-features": StoryFeatures | null;
  metadata: object;
  "publish-at": number | null;
  "assignee-name": string;
  "last-updated-by": LastUpdatedBy | null;
  "social-cards"?: SocialCard[];
  "social-accounts"?: SocialAccount[];
  "social-publish"?: BNSocialCard[];
  "asana-project-id"?: string;
  "social-cards-history"?: SocialCard[];
  "task-id"?: TaskId;
  "embargoed-till": Timestamp | null;
}

export interface Alternative {
  alternative: {
    location: {
      default: {
        headline: string;
        "hero-image": {
          "member-id": number;
          caption: string;
          attribution: string;
          "creator-id": number | null;
          "alt-text": string;
        };
      };
    };
  };
}
export interface Contribution {
  "role-id": number;
  "role-name": string;
  authors: Author[];
}

export interface UnsavedContribution {
  id: number;
  "contributors-ids": ContributorId[];
}

export type AnyContribution = Contribution | UnsavedContribution;

export enum AccessType {
  Subscription = "subscription",
  Login = "login",
  Public = "public"
}
export interface EditorNotes {
  "editor-notes": string;
}
export interface PushNotificationTargets {
  mobile: boolean;
  web: boolean;
}

export interface Tree {
  "content-id": CardId;
  "content-type"?: string;
  metadata?: object;
  "version-id"?: string;
  "card-added-at"?: Timestamp;
  "card-updated-at"?: Timestamp;
  "client-id"?: ClientId;
}

export interface SEO {
  "meta-description"?: string;
  "meta-title"?: string;
  "meta-google-news-standout"?: Boolean;
  "meta-keywords"?: string[];
  "claim-review"?: ClaimReview;
}

export interface ClaimReview {
  enabled: boolean;
  itemReviewed: {
    author: {
      name: string;
      sameAs: string;
    };
    datePublished: string;
  };
  claimReviewed: string;
  reviewRating: {
    ratingValue: string;
    alternateName: string;
  };
}

// Editor Config
interface CardType {
  "content-id": CardId;
  "card-type"?: string;
  "content-type": string;
}

interface EditorFeatures {
  [index: string]: boolean;
}

export interface StoryElementTemplate {
  subtype?: StoryElementSubType<StoryElementType>;
  type: StoryElementType;
  id: string;
}
export interface CardTemplate {
  [key: string]: {
    "story-elements": StoryElementTemplate[];
  };
}

export interface EditorConfig {
  name?: string;
  slug?: string;
  cards?: CardTemplate;
  "editor-features"?: EditorFeatures | {};
  tree?: Array<CardType>;
}

export type UnsavedStory = Pick<
  Story,
  | "tree"
  | "cards"
  | "story-content-id"
  | "story-template"
  | "headline"
  | "subheadline"
  | "push-notification-targets"
  | "metadata"
  | "seo"
  | "alternative"
  | "story-elements"
  | "updated-cards"
  | "tags"
  | "entities"
  | "asana-project-id"
  | "private-fields"
  | "authors"
  | "story-features"
>;

export enum StoryTemplate {
  Text = "text",
  Photo = "photo",
  Video = "video",
  Listicle = "listicle",
  StoryListicle = "story-listicle",
  LiveBlog = "live-blog",
  BreakingNews = "breaking-news",
  NewsElseWhere = "news-elsewhere",
  Review = "review",
  Interview = "interview"
}

export interface StoryFeatures {
  "push-notification": PushNotificationStoryFeature;
}

export const StoryFeatureDefaults = (): StoryFeatures => {
  return {
    "push-notification": {
      "is-scheduled": false,
      "publish-at": null
    }
  };
};

export interface PushNotificationStoryFeature {
  "is-scheduled": boolean;
  "publish-at": number | null;
}

export interface WorkflowTransition {
  action:
    | DraftActions
    | PendingApprovalActions
    | ApprovedActions
    | ScheduledActions
    | PublishedActions
    | RejectedActions
    | VersionActions;
  "display-name": string;
  "next-status": StoryStatus;
  style: string;
}

export type WorkflowTransitions = WorkflowTransition[];

export type AnyStory = UnsavedStory | Story;

export type StoryWithoutSlug = Omit<AnyStory, "slug">;

interface StoryVersion {
  id: StoryId;
  "story-content-id": StoryId;
}

export interface GetResponse {
  story: Story;
  "workflow-transitions": WorkflowTransitions;
}

export type SaveResponse = GetResponse & {
  "story-version": StoryVersion;
};
export interface CardWithId {
  "content-id": CardId;
}

export const getStory = (storyId: StoryId, params?: { versionId?: StoryId }): Promise<GetResponse> => {
  const url =
    params && params.versionId
      ? `/api/story/${storyId}?format=itsman-x&story-version-id=${params.versionId}`
      : `/api/story/${storyId}?format=itsman-x`;
  return client
    .url(url)
    .get()
    .json();
};

export const getPublishedStory = (storyId: StoryId): Promise<GetResponse> => {
  const url = `/sketches/api/stories/${storyId}`;
  return client
    .url(url)
    .get()
    .json();
};

export const saveStory = (storyId: StoryId, story: Story): Promise<SaveResponse> => {
  return client
    .url(`/api/story/${storyId}/story-version?format=itsman-x`)
    .json(story)
    .post()
    .json();
};

export const saveNewStory = (storyId: StoryId, story: UnsavedStory): Promise<SaveResponse> => {
  return client
    .url(`/api/story/${storyId}/story-version?format=itsman-x`)
    .json(story)
    .post()
    .json();
};

export const generatePlagiarismReport = (storyContentId: StoryId, storyVersionId: StoryId): Promise<any> => {
  return client
    .url(`/api/story/${storyContentId}/plagiarism-check`)
    .json({
      "story-version-id": storyVersionId
    })
    .post()
    .json();
};
