/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import {
  translateOverlayPointToFocusPoint,
  Dimension,
  Coordinate
} from "pages/media-library/components/image-preview-with-actions/utils";
import { ImageMetadata } from "api/search-media-image";
import { getPosition } from "pages/media-library/image-utils";
import classnames from "classnames/bind";
import styles from "./focus-point.module.css";

const cx = classnames.bind(styles);

const setFocusPoint = (metadata: Dimension, point: Coordinate, overlayDimensions: Dimension) => {
  const imageDimensions = {
    width: metadata.width,
    height: metadata.height
  };

  const { x, y } = translateOverlayPointToFocusPoint(overlayDimensions, imageDimensions, point);

  if (0 <= x && x < imageDimensions.width && 0 <= y && y < imageDimensions.height) {
    const newMetadata = { ...metadata, "focus-point": [x, y] };
    return newMetadata;
  }
  return metadata;
};

const setSelectedFocusPoint = (
  e: React.MouseEvent,
  imageMetadata: Dimension,
  overlayDimensions: Dimension,
  imageIndex = 0
) => {
  var parentPosition = getPosition(e.target, imageIndex);

  const point = {
    x: e.clientX - parentPosition.x,
    y: e.clientY - parentPosition.y
  };

  return setFocusPoint(imageMetadata, point, overlayDimensions);
};

const setDefaultFocusPoint = (event: React.MouseEvent, metadata: ImageMetadata, overlayDimensions: Dimension) => {
  event.stopPropagation();
  const point = {
    x: overlayDimensions.width / 2,
    y: overlayDimensions.height / 2
  };
  return setFocusPoint(
    metadata ? metadata : { width: overlayDimensions.width / 2, height: overlayDimensions.height / 2 },
    point,
    overlayDimensions
  );
};

const removeFocusPoint = (event: React.MouseEvent, imageMetadata: ImageMetadata) => {
  event.stopPropagation();
  const { "focus-point": removed, ...metadata } = imageMetadata;

  return metadata;
};

interface FocusPointProps {
  x: number;
  y: number;
  classname?: string;
}

const FocusPoint: React.FC<FocusPointProps> = ({ x, y, classname = "" }) => {
  const positionLeft = x,
    positionTop = y;

  return (
    <span className={cx("image-focus-point-crosshair", classname)} style={{ top: positionTop, left: positionLeft }} />
  );
};

export { setSelectedFocusPoint, setDefaultFocusPoint, removeFocusPoint, FocusPoint };
