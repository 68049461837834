/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Image } from "api/search-media-image";
import {
  ClaimReview,
  UnsavedStory,
  Story,
  StoryStatus,
  StoryTemplate,
  AccessType,
  StoryFeatureDefaults
} from "api/story";
import formatDate from "date-fns/format";
import { Timeline, TimelineEvent, ActionNames } from "api/activity-log";
import { INITIAL_STORY_TASK_STATE, INITIAL_TASK_UI_STATE } from "../components/task-planner/task-state";
import { PlagiarismCheck, StoryEditorState, VideoOembedSearchPages, VideoOEmbedSelector } from "../state";
import generateInspectorDataByRoute from "../components/inspector/inspector-data";
import { Fields } from "api/route-data/story-route-data";

export const INITIAL_HERO_IMAGE_STATE: Image = {
  key: "",
  url: "",
  metadata: {
    width: 0,
    height: 0
  },
  caption: null,
  attribution: null,
  hyperlink: null,
  "alt-text": null
};

export const INITIAL_UNSAVED_STORY: UnsavedStory = {
  tree: [],
  cards: {},
  "story-content-id": "new",
  "story-template": StoryTemplate.Text,
  headline: "",
  subheadline: "",
  "push-notification-targets": [],
  metadata: {},
  seo: {},
  alternative: {},
  "story-elements": {},
  "updated-cards": [],
  tags: [],
  entities: {},
  "private-fields": {
    "editor-notes": ""
  },
  authors: null,
  "story-features": StoryFeatureDefaults()
};

export const INITIAL_SAVED_STORY: Story = {
  "embargoed-till": 0,
  "updated-at": 0,
  seo: {},
  "assignee-id": 0,
  "author-name": "",
  tags: [],
  headline: "",
  "storyline-id": null,
  votes: {},
  "push-notification-targets": [],
  "story-content-id": "new",
  "is-published": false,
  slug: "",
  "last-published-at": null,
  "last-updated-by": null,
  tree: [],
  subheadline: "",
  alternative: {},
  sections: [],
  "updated-cards": [],
  "access-level-value": null,
  "content-created-at": 0,
  "owner-name": "",
  "custom-slug": null,
  "push-notification": null,
  "publisher-id": 0,
  comments: null,
  "is-embargoed": false,
  "hero-image": INITIAL_HERO_IMAGE_STATE,
  entities: {},
  "published-at": null,
  "story-elements": {},
  "breaking-news-linked-story-id": null,
  "storyline-title": null,
  "private-fields": {
    "editor-notes": ""
  },
  summary: null,
  "push-notification-title": null,
  "external-id": null,
  "canonical-url": null,
  autotags: [],
  "linked-entities": [],
  status: StoryStatus.Draft,
  "bullet-type": "",
  id: "",
  "editor-features": {},
  cards: {},
  "story-version-id": "",
  "content-type": "story",
  "content-updated-at": 0,
  "author-id": 0,
  "owner-id": 0,
  access: AccessType.Public,
  "first-published-at": null,
  version: 0,
  "story-template": StoryTemplate.Text,
  "sequence-no": null,
  "created-at": 0,
  authors: null,
  contributors: [],
  metadata: {},
  "publish-at": null,
  "assignee-name": "",
  "social-cards": [],
  "social-accounts": [],
  "story-features": StoryFeatureDefaults()
};

export const INITIAL_CLAIM_REVIEW_STATE: ClaimReview = {
  enabled: false,
  itemReviewed: {
    author: {
      name: "",
      sameAs: ""
    },
    datePublished: formatDate(Date.now(), "yyyy-MM-dd")
  },
  claimReviewed: "",
  reviewRating: {
    ratingValue: "",
    alternateName: ""
  }
};

export const INITIAL_TIMELINE_EVENT: TimelineEvent = {
  id: 0,
  "member-id": 0,
  changed: 0,
  "to-status": StoryStatus.Draft,
  "from-status": StoryStatus.Draft,
  "content-id": "",
  "content-version-id": "",
  "member-name": "",
  "action-name": ActionNames.StorySubmit
};

export const INITIAL_TIMELINE: Timeline = {
  events: [],
  page: {
    total: 0,
    limit: 10,
    offset: 0
  }
};

export const INITIAL_PLAGIARISM_CHECK_STATE: PlagiarismCheck = {
  message: null,
  url: null,
  loading: false
};

export const DEFAULT_OEMBED_VIDEO_PROVIDER = {
  name: "Youtube",
  id: "youtube"
};

export const INITIAL_VIDEO_OEMBED_SEARCH_PAGES: VideoOembedSearchPages = {
  page: 0,
  total: 0,
  limit: 10,
  offset: 0,
  token: {
    prev: null,
    next: null
  }
};

export const INITIAL_VIDEO_OEMBED_SELECTOR_STATE: VideoOEmbedSelector = {
  app: {
    term: "",
    videos: [],
    selectedFilters: {
      provider: DEFAULT_OEMBED_VIDEO_PROVIDER
    }
  },
  ui: {
    pages: INITIAL_VIDEO_OEMBED_SEARCH_PAGES,
    searchLoading: false,
    showSearchFilters: false
  }
};

export const INITIAL_STORY_TEMPLATE_FIELDS: Fields = {};

export const INITIAL_STATE: StoryEditorState = {
  story: INITIAL_UNSAVED_STORY,
  storyTemplateFields: INITIAL_STORY_TEMPLATE_FIELDS,
  initialEditorState: null,
  editorState: null,
  taskState: INITIAL_STORY_TASK_STATE,
  timeline: INITIAL_TIMELINE,
  plagiarismCheck: INITIAL_PLAGIARISM_CHECK_STATE,
  videoOEmbedSelector: INITIAL_VIDEO_OEMBED_SELECTOR_STATE,
  editorConfig: null,
  app: {
    importCard: {
      storySearchTerm: "",
      storyList: [],
      selectedStory: null,
      selectedCardId: ""
    },
    photoEditor: {
      visible: false,
      selectedImage: null,
      imageAs: "",
      imageId: ""
    },
    storyAuthorContributions: [],
    asanaProject: null,
    location: {
      selectedPlace: ""
    }
  },
  ui: {
    storyElements: {},
    editorState: null,
    showSponsoredContent: false,
    recommendedTags: {},
    showAlternateTitle: false,
    isStoryModified: false,
    isStoryChangeInProgress: false,
    lastUpdatedTime: 0,
    workflowTransitions: [],
    imageUploading: false,
    pollImageUploading: false,
    storyContributions: [],
    errors: {
      inspector: {},
      editor: {}
    },
    publishInspector: null,
    publishInspectorValidationErrors: null,
    alternativeLocations: ["home", "social"],
    inspector: generateInspectorDataByRoute("", {}),
    imageForInspector: null,
    isStoryLocked: false,
    restoreVersionId: null,
    readOnlyStory: null,
    readOnlyEditorState: null,
    showStoryRestoreModal: false,
    showStoryCleanUpModal: false,
    changeStatusAction: null,
    timelineEvent: INITIAL_TIMELINE_EVENT,
    showPhotoEditor: false,
    loading: false,
    banner: null,
    isStorySaving: false,
    isStoryPublishing: false,
    isStatusTransitionInProgress: false,
    isFormattingToolbarActive: false,
    taskUI: INITIAL_TASK_UI_STATE,
    numberOfCardsShown: 0,
    recentlyAddedCardId: null,
    recentlyAddedElementId: null,
    cardsLoaded: [],
    cardsToLoad: [],
    currentCardLoading: null,
    seoChecked: false,
    isViewingReadOnlyVersion: false,
    isTextParaphrasingInProgress: false
  },
  errors: []
};

export default INITIAL_STATE;
