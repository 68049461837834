/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { clearAccessTokens } from "swallow";
import { Publisher, Member } from "api/route-data/route-data";
import styles from "./avatar.module.css";
import { t } from "i18n";
import WithGAEvent from "helpers/ga-analytics/with-ga-event";
import { QuestionCircle } from "@quintype/em/icons/question-circle";

interface Props {
  member: Member;
  publisher: Publisher;
  isTaskPlannerEnabled?: boolean;
  helpUrl: string;
}

interface State {
  isDetailsOpen: boolean;
}
class Avatar extends React.Component<Props, State> {
  private avatarRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDetailsOpen: false
    };
    this.avatarRef = React.createRef();
  }

  closeProfileDetails = (e: MouseEvent) => {
    if (this.avatarRef.current!.contains(e.target! as Node)) {
      return;
    }

    this.setState({ isDetailsOpen: false });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.closeProfileDetails);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.closeProfileDetails);
  }

  toggleProfileDetails = () => this.setState((prevState) => ({ isDetailsOpen: !prevState.isDetailsOpen }));

  logout = () => {
    clearAccessTokens();
    window.location.href = "/logout";
  };

  render() {
    const { member, publisher, isTaskPlannerEnabled, helpUrl } = this.props;

    const helpClick = () => {
      window.open(helpUrl, "_blank");
    };

    return (
      <div ref={this.avatarRef} className={styles["avatar-container"]} data-test-id="avatar-container">
        <div className={styles["question-circle"]} data-test-id="question-circle" onClick={() => helpClick()}>
          <QuestionCircle label={t("help-video")} />
        </div>
        <div
          className={styles["avatar"]}
          data-test-id="avatar-container-btn"
          onClick={() => this.toggleProfileDetails()}>
          <div className={styles["avatar__image-container"]} data-test-id="avatar-image-container">
            {member["avatar-url"] && (
              <img
                className={styles["avatar__image"]}
                data-test-id="avatar-image"
                src={member["avatar-url"]!}
                alt={member.name}
              />
            )}
          </div>
          <span className={styles["avatar__publisher"]} data-test-id="avatar-publisher-name">
            {publisher.name}
          </span>
          <span className={styles["avatar__name"]} data-test-id="avatar-publisher-member-name">
            {member.name}
          </span>
        </div>

        {this.state.isDetailsOpen && (
          <div className={styles["profile-details"]} data-test-id="profile-details">
            <ul className={styles["profile-actions"]} data-test-id="profile-actions-list">
              <WithGAEvent label="header-me-profile">
                <li className={styles["profile-action-item"]} data-test-id="profile-action-my-profile">
                  <a
                    href={`/user/profile`}
                    className={styles["profile-action-link"]}
                    data-test-id="profile-action-my-profile-btn">
                    {t("user-page.my_profile")}
                  </a>
                </li>
              </WithGAEvent>
              <WithGAEvent label="header-me-tasks">
                {isTaskPlannerEnabled && (
                  <li className={styles["profile-action-item"]} data-test-id="profile-action-my-tasks">
                    <a
                      href="/user/tasks"
                      className={styles["profile-action-link"]}
                      data-test-id="profile-action-my-tasks-btn">
                      {t("tasks.my_tasks.title")}
                    </a>
                  </li>
                )}
              </WithGAEvent>
              <WithGAEvent label="header-me-logout">
                <li className={styles["profile-action-item"]} data-test-id="profile-action-logout">
                  <button
                    onClick={this.logout}
                    className={styles["profile-action-link"]}
                    data-test-id="profile-action-logout-btn">
                    Logout
                  </button>
                </li>
              </WithGAEvent>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default Avatar;
