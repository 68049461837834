/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Header from "components/header/header";
import Navbar from "components/navbar/navbar";
import Snackbar from "components/snackbar/snackbar";
import Banner from "components/banner/banner";
import WrapErrorPage from "containers/error-page/error-page";
import { Publisher, Member, Features } from "api/route-data/route-data";
import { Role } from "api/users";
import { navigate } from "../../utils/routes.utils";
import { connect } from "react-redux";

import classnames from "classnames/bind";
import { ThunkDispatch } from "redux-thunk";
import { Location } from "history";
import { match as Match } from "react-router";
import { compose } from "redux";
import { setBodyOverflow } from "utils/dom.utils";
import { initialize as initI18N } from "../../i18n";
import { I18nextProvider } from "react-i18next";
import { t } from "i18n";
import NewRelic from "new-relic-browser";
import { fetchRouteData } from "store/route-data";
import { updateViewportSize, selectIsDesktopSizeViewport } from "store/viewport";
import styles from "./page.module.css";
import ErrorBoundary from "components/error-boundary/error-boundary";

const cx = classnames.bind(styles);

interface Options {
  HeaderComponent?: React.ComponentType<any>;
  ActionBarComponent?: typeof React.Component;
  isStoryPage?: boolean;
}

interface PartialAppState {
  config: {
    publisher: Publisher;
    member: Member;
    publisherWideBannerMessage: string | null;
    smartkarrot: any;
  };
  features: Features;
  storyEditor: {
    ui: {
      banner: { message: string } | null;
    };
  };
  usersAndRoles: {
    usersPage: {
      ui: {
        banner: {
          message: string;
        };
      };
    };
  };
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

interface OwnProps {
  match: Match;
  title: string;
  location: Location;
}

interface StateProps {
  publisher: Publisher;
  member: Member;
  features: Features;
  url: string;
  title: string;
  publisherWideBannerMessage: string | null;
  isBannerPresent: boolean;
  isDesktopSizeViewport: boolean;
  location: Location;
  match: Match;
  smartkarrot: any;
}

interface DispatchProps {
  navigate: (path: string, params?: { [key: string]: number | string | boolean }) => void;
  fetchRouteData: (path: string) => void;
  setViewportSize(isDesktopSizeViewport: boolean): void;
}

type Props = StateProps & DispatchProps & OwnProps;

export interface ItsmanWindow extends Window {
  zE: Function | null;
  $zopim: any;
  UsageAnalytics: any;
  newrelic: typeof NewRelic;
  Intercom: Function | null;
}

export const wrapPageComponent = (opts: Options = {}) => (Component: React.ComponentType<any>) => {
  const { HeaderComponent = Header, ActionBarComponent = null, isStoryPage = false } = opts;
  return class Page extends React.Component<Props> {
    componentDidMount() {
      const { url, fetchRouteData } = this.props;
      fetchRouteData(url);
      window.addEventListener("resize", this.updateViewport);
      setBodyOverflow("auto");
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateViewport);
    }

    componentDidUpdate(prevProps: StateProps) {
      const { publisher, member, match, title, isDesktopSizeViewport } = this.props;

      const w = window as ItsmanWindow;

      // Check if member prop value or view port size is changed
      if (prevProps.member !== member || prevProps.isDesktopSizeViewport !== isDesktopSizeViewport) {
        if (w.zE && member.id) {
          w.zE(function() {
            w.$zopim(function() {
              w.$zopim.livechat.setName(member.name);
              w.$zopim.livechat.setEmail(member["communication-email"]);
              w.$zopim.livechat.theme.setColor("#4860bc");
              isDesktopSizeViewport ? w.$zopim.livechat.button.show() : w.$zopim.livechat.button.hide();
            });
          });
        }

        // register member with smartKarrot
        if (w.UsageAnalytics && member.id) {
          try {
            const roles: Role[] = member.roles || [];
            const memberIdString = member.id.toString();
            const publisherIdString = this.props.publisher.id.toString();
            const { "app-id": smartkarrotAppId, "api-key": smartkarrotApiKey } = this.props.smartkarrot;

            if (smartkarrotAppId && smartkarrotApiKey) {
              w.UsageAnalytics.configure(smartkarrotAppId, publisherIdString, "option-c");
              w.UsageAnalytics.setUser(memberIdString, publisherIdString);
              w.UsageAnalytics.setUserAttribute("userId", memberIdString);
              w.UsageAnalytics.setUserAttribute("name", member.name);
              member["communication-email"] &&
                w.UsageAnalytics.setUserAttribute("emailId", member["communication-email"]);
              if (roles && roles[0]) {
                const roleString = roles.map((r) => r.name).join(",");
                roleString && w.UsageAnalytics.setUserAttribute("roles", roleString);
              }
            }
          } catch (e) {
            console.error("Could not register with Smartkarrot.", e);
          }
        }

        if (member.settings && member.settings["dark-mode"]) {
          const css = `:root {
          --brand-1: #1e1e1f;
          --brand-2: #3a3a3a;
          --brand-3: #4860bc;
          --brand-4: #324383;
          --mono-1: #252525;
          --mono-2: #36383a;
          --mono-3: #3d3f42;
          --mono-4: #e0e0e0;
          --mono-5: #d6dde4;
          --danger: #ff214b;
          --success: #2fd072;
          --warning: #f5a623;
          --white: #2f2f31;
          --black: #fff;
          --accent-1: #f8e71c;
          --accent-2: #7842b9;
          --ascent-5:  #f5a623;
        }`,
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

          style.type = "text/css";

          style.appendChild(document.createTextNode(css));

          head.appendChild(style);
        }

        if (w.Intercom) {
          w.Intercom("update", {"hide_default_launcher": !isDesktopSizeViewport})
        }
      }

      // Check if publisher or match or title value is changed
      if (prevProps.publisher !== publisher || prevProps.match !== match || prevProps.title !== title) {
        if (document.documentElement && publisher && publisher.i18n) {
          document.documentElement.lang = publisher.i18n.locale;
          document.documentElement.dir = publisher.i18n.dir || "ltr";
          document.title = isStoryPage && match.params["id"] !== "new" ? title : t(`meta_title.${title}`);
        }
      }
    }

    updateViewport = () => {
      const newViewport = window.matchMedia("(min-width: 1024px)").matches;
      if (this.props.isDesktopSizeViewport !== newViewport) this.props.setViewportSize(newViewport);
    };

    render() {
      const {
        publisher,
        member,
        features,
        match,
        location,
        isBannerPresent,
        isDesktopSizeViewport,
        publisherWideBannerMessage,
        navigate
      } = this.props;

      const pageClasses = cx("page-wrapper", {
        "story-page-wrapper": isStoryPage,
        "with-banner": isBannerPresent
      });
      const pageContentsContainerClasses = cx("page-contents-container", { "story-page-contents": isStoryPage });
      const pageContentsClasses = cx("page-contents", {
        "story-page-contents": isStoryPage
      });

      const userWithoutRoles = member && member.id && member.roles && member.roles.length === 0;
      userWithoutRoles && navigate("/login#no-roles-assigned");

      return (
        <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}>
          {publisher.i18n && (
            <I18nextProvider i18n={initI18N(publisher.i18n.locale)}>
              {member.id && (
                <React.Fragment>
                  {publisherWideBannerMessage && <Banner message={publisherWideBannerMessage} />}
                  <HeaderComponent location={location} />
                  <Navbar
                    features={features}
                    isBannerPresent={isBannerPresent}
                    renderInDesktopView={isDesktopSizeViewport}
                    location={location}
                    match={match}
                    navigate={navigate}
                  />
                  <div data-test-id="page-wrapper" className={pageClasses}>
                    {ActionBarComponent && (
                      <ActionBarComponent
                        match={match}
                        isBannerPresent={isBannerPresent}
                        isDesktopSizeViewport={isDesktopSizeViewport}
                      />
                    )}
                    <div className={pageContentsContainerClasses} data-test-id="page-contents-container">
                      <main data-test-id="page-contents" className={pageContentsClasses}>
                        <WrapErrorPage>
                          <ErrorBoundary>
                            <Component {...this.props} />
                          </ErrorBoundary>
                        </WrapErrorPage>
                      </main>
                    </div>
                  </div>
                  <Snackbar />
                </React.Fragment>
              )}
            </I18nextProvider>
          )}
        </div>
      );
    }
  };
};

const wrapPage = (opts: Options = {}) => (Component: React.ComponentType<any>) => {
  const mapStateToProps = (state: PartialAppState, ownProps: OwnProps): StateProps => {
    return {
      publisher: state.config.publisher,
      member: state.config.member,
      features: state.features,
      url: ownProps.match.url,
      title: ownProps.title,
      publisherWideBannerMessage: state.config.publisherWideBannerMessage,
      isBannerPresent: !!(
        state.config.publisherWideBannerMessage ||
        (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message) ||
        state.usersAndRoles.usersPage.ui.banner.message
      ),
      isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
      location: ownProps.location,
      match: ownProps.match,
      smartkarrot: state.config.smartkarrot
    };
  };

  const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
    return {
      navigate: (path: string, params?: { [key: string]: number | string | boolean }) =>
        dispatch(navigate(path, params)),
      fetchRouteData: (path) => dispatch(fetchRouteData(path)),
      setViewportSize: (isDesktopSizeViewport) => dispatch(updateViewportSize({ isDesktopSizeViewport }))
    };
  };

  return compose(connect(mapStateToProps, mapDispatchToProps), wrapPageComponent(opts))(Component);
};

export default wrapPage;
