/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import { t } from "i18n";
import RenderMultipleRow from "components/render-multiple-row/render-multiple-row";
import { compact } from "lodash";
import { flatten, isRange, convertRange } from "utils";

const setAttributeValues = (selectedAttributes, selectedAttribute, values) =>
  selectedAttributes.map((storyAttribute) =>
    storyAttribute.name === selectedAttribute.name ? { ...storyAttribute, values } : storyAttribute
  );

const setAttribute = (selectedAttributes, currentAttribute, storyAttribute) => {
  const newStoryAttribute = { ...storyAttribute, values: [] };

  if (!currentAttribute) {
    return selectedAttributes.concat([newStoryAttribute]);
  }

  let found = false;

  const results = selectedAttributes.map((selectedAttribute) => {
    if (selectedAttribute.name === currentAttribute.name) {
      found = true;
      return newStoryAttribute;
    } else {
      return selectedAttribute;
    }
  });

  return found ? results : selectedAttributes.concat([newStoryAttribute]);
};

const getAttributeValues = (storyAttributes, selectedAttribute) => {
  const values = selectedAttribute
    ? storyAttributes.find((storyAttribute) => storyAttribute.name === selectedAttribute.name).values
    : [];

  return selectedAttribute && selectedAttribute["data-type"] === "entity"
    ? values
    : flatten(
        values.map((value) => {
          return isRange(value) ? convertRange(value) : value;
        })
      );
};

const StoryAttributeFilter = ({ storyAttributes, value: selectedAttributes, onChange }) => {
  const selectedAttributeNames = selectedAttributes.map((storyAttribute) => storyAttribute.name);
  return (
    <div className="attributes-and-value-container">
      <RenderMultipleRow
        columnLabels={[t("workspace.filters_inspector.attribute"), t("workspace.filters_inspector.values")]}
        columnRenders={[
          (index) => {
            const selectedAttribute = selectedAttributes.length > 0 ? selectedAttributes[index] : null;
            return (
              <Select
                value={selectedAttribute}
                onChange={(storyAttribute) =>
                  onChange(setAttribute(selectedAttributes, selectedAttribute, storyAttribute))
                }
                options={storyAttributes.filter(
                  (storyAttribute) =>
                    !selectedAttributeNames.includes(storyAttribute.name) && storyAttribute["data-type"] !== "entity"
                )}
                getOptionLabel={(storyAttribute) => storyAttribute["display-name"]}
                getOptionValue={(storyAttribute) => storyAttribute["name"]}
                hideError={true}
              />
            );
          },
          (index) => {
            const selectedAttribute = selectedAttributes.length > 0 ? selectedAttributes[index] : null;
            return (
              <Select
                value={selectedAttribute && selectedAttribute.values}
                onChange={(values) => onChange(setAttributeValues(selectedAttributes, selectedAttribute, values))}
                options={getAttributeValues(storyAttributes, selectedAttribute)}
                getOptionLabel={(attribute) => attribute}
                getOptionValue={(attribute) => attribute}
                isMulti={true}
                isDisabled={!selectedAttribute}
                hideError={true}
              />
            );
          }
        ]}
        name="story-attribute"
        selectedLHSValues={selectedAttributes}
        onDelete={(selectedAttributes) => {
          const selectedAttributesWithoutNull = compact(selectedAttributes);
          onChange(selectedAttributesWithoutNull);
        }}
      />
    </div>
  );
};

export default StoryAttributeFilter;
