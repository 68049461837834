/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { EditorState } from "prosemirror-state";
import debounce from "lodash/debounce";
import EditorView from "./editor-view";
import { actions } from "pages/story-editor/actions";
import { storyChangeInProgress } from "pages/story-editor/action-creators";
import nodeViews from "./node-views";
import "./story-editor.module.css";

interface StateProps {
  initialEditorState: EditorState | null;
  isStoryChangeInProgress: boolean;
  isStorySaving: boolean;
}

interface DispatchProps {
  onChange(editorState: EditorState): void;
  setStoryChangeInProgress(): void;
}

interface OwnProps {
  readOnly: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  editorState: EditorState | null;
}

/*
 TODO: [Sunil | 05-07-21] Think of merging and abstracting this component and
   ProseMirrorTextArea component and making them domain independent.
 */
export class ProseMirrorStoryEditor extends React.PureComponent<Props, State> {
  debouncedOnChange: (editorState: EditorState | null) => void;

  constructor(props: Props) {
    super(props);

    this.state = { editorState: props.initialEditorState };

    this.debouncedOnChange = debounce(this.props.onChange, 250);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.initialEditorState !== prevProps.initialEditorState) {
      this.setState({ editorState: this.props.initialEditorState }, () =>
        this.debouncedOnChange(this.props.initialEditorState)
      );
    }
  }

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState }, () => {
      const editorState = this.state.editorState;
      this.props.isStorySaving ? this.props.onChange(editorState!) : this.debouncedOnChange(editorState);
      this.props.isStoryChangeInProgress || this.props.setStoryChangeInProgress();
    });
  };

  render() {
    return (
      <EditorView
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}
        nodeViews={nodeViews}
        readOnly={this.props.readOnly}
        classname="prosemirror-story-editor"
      />
    );
  }
}

function mapStateToProps(state): StateProps {
  return {
    initialEditorState: state.storyEditor.initialEditorState,
    isStorySaving: state.storyEditor.ui.isStorySaving,
    isStoryChangeInProgress: state.storyEditor.ui.isStoryChangeInProgress
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    onChange: (editorState) => dispatch({ type: actions.SET_EDITOR_STATE, payload: { editorState } }),
    setStoryChangeInProgress: () => dispatch(storyChangeInProgress())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProseMirrorStoryEditor);
