/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import startofmonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";

const convertDateToUTC = (dateString) => Date.parse(dateString);

const buildSearchQuery = (filterSet) => {
  let today = new Date().setHours(0, 0, 0, 0);
  let now = Date.now();
  let query = {
    fields:
      "authors,author-name,author-id,content-type,q,headline,message,is-published,linked-story-id,slug,story-content-id,story-template,updated-at,id,status,publish-at,last-published-at,embargoed-till,is-embargoed",
    aggregation: "dashboard"
  };

  const datesFn = (dateFilter) => {
    switch (dateFilter.type) {
      case "today": {
        return [today, now];
      }
      case "this-week": {
        return [convertDateToUTC(startOfWeek(today)), now];
      }
      case "this-month": {
        return [convertDateToUTC(startofmonth(today)), now];
      }
      case "custom": {
        return [dateFilter.from, dateFilter.to];
      }
      default:
        throw new Error("Workspace: Unknown date filter type received: " + dateFilter.type);
    }
  };

  query["search-type"] = "latest";

  const term = filterSet.term && filterSet.term.trim();

  if (term) {
    if (query["search-type"] === "push-notification") {
      query["message"] = term;
    } else query["q"] = term;
  } else {
    query["sort"] = filterSet.selectedTabSlug === "published" ? "published-at" : "updated-at";
  }

  if (filterSet["updated-at"]["type"])
    [query["updated-after"], query["updated-before"]] = datesFn(filterSet["updated-at"]);

  if (filterSet["published-at"] && filterSet["published-at"]["type"])
    // for backward compatibility with existing filters
    [query["published-after"], query["published-before"]] = datesFn(filterSet["published-at"]);

  if (filterSet["story-templates"].length) {
    query["type"] = filterSet["story-templates"].map((template) => template.slug).join(",");
  }

  if (filterSet.sections.length)
    query["section-id"] = filterSet.sections.map((section) => section["tree-ids"]).join(",");

  if (filterSet.attributes.length) {
    filterSet.attributes.forEach((attribute) => {
      query["story-attributes." + attribute.name] = attribute.values.join(",");
    });
  }

  if (filterSet.authors.length) query["author-id"] = filterSet.authors.map((author) => author.id).join(",");

  if (filterSet["is-embargoed"]) {
    query["is-embargoed"] = filterSet["is-embargoed"];
  }

  query["post-filter-status"] = filterSet.selectedTabSlug;

  query["decay-field"] = "updated-at";

  if (filterSet.limit) {
    query["limit"] = filterSet.limit;
  }

  if (filterSet.source.source) {
    query["sources"] = [{ name: filterSet.source.source, categories: filterSet.source.categories }];
  }

  query["highlighter"] = "workspace";
  return query;
};
export default buildSearchQuery;
