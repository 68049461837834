/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { PushNotificationListItem } from "./push-notification";
import { client } from "./client";
import { Section } from "./route-data/route-data";
import { Attribute } from "./story-attributes";
import { StoryAttribute } from "./route-data/story-route-data";
import { DateFilter } from "components/date-filter/date-filter";

export interface AiStoryTranslationConfig {
  "source-language": string;
  "target-language": string;
  source: string;
  "target-publisher"?: { id: number; name: string };
}

export interface AiContentTranslationConfig {
  story: AiStoryTranslationConfig[];
}

export interface StoryCloneConfig {
  "target-publisher"?: { id: number; name: string };
}

export interface ContentCloneConfig {
  story: StoryCloneConfig[];
}

export interface PartialAppState {
  config: {
    tabs: Tab[];
    sections: Section[];
    storyTemplates: StoryTemplate[];
    sources: Source[];
    storyAttributes: StoryAttribute[];
    "ai-content-translation": AiContentTranslationConfig;
    "content-clone": ContentCloneConfig;
  };
  features: Features;
  workspace: {
    stories: Story[];
    aggregations: {
      status: Status;
    };
    currentFilterSet: FilterSet;
    stagingFilterSet: FilterSet;
    isBulkActionEnabled: boolean;
    hits: number;
    bulkSelectedStoryIds: string[];
    bulkAccessLevelValue: number | null;
    allStoriesSelected: boolean;
    showLoadingIndicator: boolean;
    selectedSavedFilter: SavedFilter;
    errors: Errors;
    pageReferer: string | null;
  };
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

export interface Source {
  name: string;
  "display-name": string;
  categories: string[];
  source?: string;
}

export class WorkspaceRequestBody {
  fields: string;
  aggregation: string;
  "search-type": string;
  "post-filter-status": string;
  sort: string;
  limit: number;
  offset: number;
  sources: Source[];
  message: string | null;
  constructor(
    fields: string,
    aggregation: string,
    searchType: string,
    sort: string,
    limit: number,
    offset: number,
    sources: Source[],
    postFilterStatus: string,
    term: string | null
  ) {
    this.fields = fields;
    this.aggregation = aggregation;
    this["search-type"] = searchType;
    this.sort = sort;
    this.limit = limit;
    this.offset = offset;
    this.sources = sources;
    this["post-filter-status"] = postFilterStatus;
    this.message = term;
  }
}

export interface Status {
  failed: number;
  scheduled: number;
  published: number;
  rejected?: number;
  all: number;
  open: number;
  approved?: number;
  draft?: number;
  "needs-approval": number;
  "pending-approval"?: number;
}

export interface Aggregations {
  status: Status;
}

export interface WorkspaceResponse {
  hits: number;
  "push-notifications": PushNotificationListItem[];
  aggregations: Aggregations;
}
interface TabBasic {
  title: string;
  statuses?: string[];
  label?: string;
}

interface TabWithStatusGroup extends TabBasic {
  "status-group": string;
}

interface TabWithValue extends TabBasic {
  value: string;
}

export type Tab = TabWithValue | TabWithStatusGroup;

export interface Story {
  "updated-at": number;
  "author-name": string;
  headline: string;
  "story-content-id": string;
  "is-published": boolean;
  slug: string;
  "last-published-at": number | null;
  "highlighted-text": string;
  "public-preview-url": string;
  status: StatusEnum;
  id: string;
  "content-type": ContentType;
  "frontend-url": string;
  "author-id": number;
  "highlighted-headline": null;
  "story-template": StoryTemplate;
  authors: Author[];
  "public-preview-key": string;
  "publish-at": null;
  metadata: any;
}

export interface Author {
  id: number;
  name: string;
  "contributor-role"?: ContributorRole;
  "highlighted-name"?: null;
  email?: string;
}

export interface ContributorRole {
  name: Name;
  id: number;
  type?: string;
}

export enum Name {
  Author = "Author"
}

export enum ContentType {
  Story = "story"
}

export enum StatusEnum {
  Approved = "approved",
  Draft = "draft",
  PendingApproval = "pending-approval",
  Published = "published"
}

export enum StoryTemplate {
  LiveBlog = "live-blog",
  Text = "text",
  Video = "video"
}

export interface Features {
  enableContentIconInNavBar: boolean;
  enableSettingsIconInNavBar: boolean;
  showPublicPreviewLink: boolean;
  canAddAuthor: boolean;
  enableGettyImagesInSidebar: boolean;
  enableContributorRolesInSidebar: boolean;
  hasAdminPermission: boolean;
  enableTagsInSidebar: boolean;
  canCreatePushNotifications: boolean;
  canCreateCollections: boolean;
  enablePromotionsInSidebar: boolean;
  isTeamsEnabled: boolean;
  canCreateBreakingNews: boolean;
  enableCollectionsInSidebar: boolean;
  enableAmpInSidebar: boolean;
  showAddNewButton: boolean;
  enableMediaLibraryIconInNavBar: boolean;
  canCreateStory: boolean;
  enableAnalyticsInNavBar: boolean;
  isDarkModeEnabled: boolean;
  isDefaultManageInspectorEnabled: boolean;
  enableAccessLevelsInSidebar: boolean;
  isUpdateNewStoryCreationEnabled: boolean;
  enableStaticPageInSidebar: boolean;
  enableBreakingNewsInSidebar: boolean;
  showAuthorFilter: boolean;
  accessLevelValue: boolean;
  enableManageIconInNavBar: boolean;
  enableCollectionsConfigureInSidebar: boolean;
  isTaskPlannerEnabled: boolean;
  isValidatorEnabled: boolean;
  enableAdminLinksInSidebar: boolean;
  enablePushNotificationInSidebar: boolean;
  showExternalSourcesFilter: boolean;
  enableEntitiesInSidebar: boolean;
  canShareFilter: boolean;
  canDeleteStory: boolean;
  canCloneStory: boolean;
  canCloneStorytoPrint: boolean;
}

export interface Errors {
  fetchingStories: boolean;
}

export interface FilterSet {
  attributes: Attribute[];
  authors: Author[];
  limit: number;
  sections: Section[];
  source: Source;
  "story-templates": [
    {
      name: string;
      slug: string;
    }
  ];
  term: string;
  "updated-at": DateFilter;
  "published-at": DateFilter;
  "is-embargoed": boolean;
}

export interface SavedFilter {
  "created-at": number;
  "deleted-at": number | null;
  filter: FilterSet;
  id: number;
  "member-id": number;
  name: string;
  "publisher-id": number;
  shared: boolean;
  "updated-at": number;
  "published-at": number;
  "is-embargoed": boolean;
}

export const loadWorkspaceData = (requestBody: WorkspaceRequestBody): Promise<WorkspaceResponse> => {
  return client
    .url(`/api/workspace`)
    .json(requestBody)
    .post()
    .json();
};
