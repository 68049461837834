/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import StoryCard from "pages/workspace/components/story-card/story-card";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import { t } from "i18n";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "components/spinner/spinner";

const StoryList = ({
  stories,
  isBulkActionEnabled,
  bulkSelectedStoryIds,
  updateStoryIds,
  allStoriesSelected,
  showPublicPreviewLink,
  canDeleteStory,
  storyTemplates,
  setStoriesOnFilterChange,
  switchToDeleteStoryRoute,
  isAllTabSelected,
  navigate,
  features,
  loadMoreStories,
  totalStories,
  errors,
  showLoadingIndicator,
  loadData
}) => (
  <InfiniteScroll
    dataLength={stories.length}
    next={loadMoreStories}
    hasMore={totalStories - stories.length > 0}
    loader={showLoadingIndicator && <Spinner message={t("workspace.story_list.loading_stories")} />}
    endMessage={
      !(errors && errors.fetchingStories) && (
        <NoContentCard>
          <File />
          <div className="no-content-message">
            {totalStories === 0 ? t("workspace.story_list.no_stories") : t("workspace.story_list.no_more_stories")}
          </div>
        </NoContentCard>
      )
    }>
    {errors && errors.fetchingStories && !showLoadingIndicator ? (
      <NoContentCard>
        <File />
        <div className="no-content-message">{t("workspace.story_list.no_content_message")}</div>
        <div className="retry" onClick={() => loadData()}>
          {t("workspace.story_list.retry")}
        </div>
      </NoContentCard>
    ) : (
      stories.map((story) => (
        <StoryCard
          features={features}
          key={`workspace-story-${story.id}`}
          story={story}
          showPublicPreviewLink={showPublicPreviewLink}
          canDeleteStory={canDeleteStory}
          storyTemplates={storyTemplates}
          setStoriesOnFilterChange={setStoriesOnFilterChange}
          isBulkActionEnabled={isBulkActionEnabled}
          checked={bulkSelectedStoryIds.includes(story.id) || allStoriesSelected}
          onChange={() => updateStoryIds(story.id)}
          switchToDeleteStoryRoute={switchToDeleteStoryRoute}
          isAllTabSelected={isAllTabSelected}
          navigate={navigate}
        />
      ))
    )}
  </InfiniteScroll>
);
export default StoryList;
