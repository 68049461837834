/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

function getPosition(element, exclusion) {
  var xPosition = 0;
  var yPosition = 0;

  const EXCLUSION = "slider-slide"; //hack for multiple image in slider

  while (element) {
    // fix for chrome 61 where webkit fixes body.scrollX/Y/Top/Left != 0 bug.
    element = element === document.body && document.scrollingElement ? document.scrollingElement : element;
    xPosition += (element.className === EXCLUSION ? 0 : element.offsetLeft) - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }
  return { x: xPosition, y: yPosition };
}

const getFileName = (image) => {
  if (!image) {
    return null;
  }

  if (image.metadata && image.metadata["file-name"]) {
    return image.metadata["file-name"];
  } else {
    const imageKey = image.key || image["temp-image-key"];
    const fileName = imageKey.split("/");
    return fileName[fileName.length - 1];
  }
};

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return {
    contentType: mimeString,
    data: new Blob([ia], { type: mimeString })
  };
}

function dataURItoFile(dataURI, fileName) {
  const blob = dataURItoBlob(dataURI);
  return new File([blob.data], fileName, { type: blob.contentType, lastModified: Date.now() });
}

export { getFileName, getPosition, dataURItoFile };
